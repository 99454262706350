import http from "./http";

const BASE_URL = "/assistance";
const assistLogin = (data, callback) => {
	http
		.post(`${BASE_URL}/login`, data)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_BANTUAN_PESERTA = {
	assistLogin,
};
