import { SERVICE_BANTUAN_PESERTA } from "@/services";
import { HELPERS } from "@/utils";
import { TYPES_BANTUAN_PESERTA } from "./bantuan-peserta.types";

const assistLogin = (data, callback) => {
	return (dispatch) => {
		dispatch({
			type: TYPES_BANTUAN_PESERTA.ASSIST_LOGIN_LOADING,
			isLoading: true,
		});
		SERVICE_BANTUAN_PESERTA.assistLogin(data, (res) => {
			if (res.success && callback) {
				callback(res.payload.payload.token);
			} else {
				if (res.error) HELPERS.showNotificationServiceError(res.error);
			}
			dispatch({
				type: TYPES_BANTUAN_PESERTA.ASSIST_LOGIN,
				payload: res.payload,
				error: res.error,
			});
		});
	};
};

export const ACTION_BANTUAN_PESERTA = { assistLogin };
